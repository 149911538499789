<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-form>
      <!-- Establecimiento Info: Input Fields -->
      <b-row>

        <!-- Field: Imagen -->
        <!-- <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Insignia"
            label-for="insignia"
          >
            <!-- Media -
            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="establecimiento.insignia"
                  :text="establecimiento.nombre == ''
                    ? 'E'
                    : avatarText(establecimiento.nombre)"
                  size="90px"
                  rounded
                  variant="light-success"
                  :title="establecimiento.nombre == ''
                    ? 'E'
                    : avatarText(establecimiento.nombre)"
                />
              </template>

              <div class="mt-1 d-inline-block">
                <h4 class="">
                  <span v-if="establecimiento.rbd">
                    {{ establecimiento.rbd }}
                  </span>
                  <span v-if="establecimiento.rbd && establecimiento.nombre">
                    <feather-icon
                      icon="ChevronsRightIcon"
                    />
                  </span>
                  <span v-if="establecimiento.nombre">
                    {{ establecimiento.nombre }}
                  </span>
                </h4>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  size="md"
                  @click="$refs.refInputEl.click()"
                >
                  <input
                    :id="establecimiento.insignia"
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    accept="image/png,image/jpeg,image/jpg"
                    @input="inputImageRenderer"
                    @change="onFileSelected"
                  >
                  <span class="d-none d-sm-inline">
                    Seleccionar insignia
                  </span>
                  <feather-icon
                    icon="UploadIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  size="sm"
                  @click="establecimiento.insignia = ''"
                >
                  <span class="d-none d-sm-inline">Eliminar</span>
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-25"
            >
              <b-card-sub-title>
                Información
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="8"
              class="mb-50"
            >
              <b-card-sub-title>
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>

            <!-- Field: RBD -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="RBD *"
                label-for="rbd"
                label-cols-md="4"
              >
                <b-form-input
                  id="rbd"
                  v-model="establecimiento.rbd"
                  placeholder="Ingresa el rbd"
                  :state="v$.establecimiento.rbd.$error === true
                  ? false
                  : null"
                  @blur.native="v$.establecimiento.rbd.$touch"
                />
                <!-- Mensajes Error Validación -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.rbd.$error"
                  id="rbdInfo"
                  class="text-right"
                >
                  <p v-for="error of v$.establecimiento.rbd.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: Nombre -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Nombre *"
                label-for="nombre"
                label-cols-md="4"
              >
                <b-form-input
                  id="nombre"
                  v-model="establecimiento.nombre"
                  placeholder="Ingresa el nombre"
                  :state="v$.establecimiento.nombre.$error === true
                  ? false
                  : null"
                  @blur.native="v$.establecimiento.nombre.$touch"
                />
                <!-- Mensajes Error Validación -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.nombre.$error"
                  id="nombreInfo"
                  class="text-right"
                >
                  <p v-for="error of v$.establecimiento.nombre.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: Abreviatura -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Abreviatura *"
                label-for="abreviatura"
                label-cols-md="4"
              >
                <b-form-input
                  id="abreviatura"
                  v-model="establecimiento.abreviatura"
                  placeholder="Ingresa la abreviatura"
                  :state="v$.establecimiento.abreviatura.$error === true
                  ? false
                  : null"
                  @blur.native="v$.establecimiento.abreviatura.$touch"
                />
                <!-- Mensajes Error Validación -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.abreviatura.$error"
                  id="abreviaturaInfo"
                  class="text-right"
                >
                  <p v-for="error of v$.establecimiento.abreviatura.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: Correo -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Correo *"
                label-for="correo"
                label-cols-md="4"
              >
                <b-input-group
                  :class="v$.establecimiento.correo.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="correo"
                    v-model="establecimiento.correo"
                    placeholder="escuela@escuela.cl"
                    :class="v$.establecimiento.correo.$error === false
                      ? ''
                      : 'border-danger rounded-right'"
                    @blur.native="v$.establecimiento.correo.$touch"
                    :state="v$.establecimiento.correo.$error === true
                    ? false
                    : null"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.correo.$error"
                  id="correoInfo"
                >
                  <p
                    v-for="error of v$.establecimiento.correo.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: Celular -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Celular *"
                label-for="celular"
                label-cols-md="4"
              >
                <b-input-group
                  :class="v$.establecimiento.celular.$error === false ? '' : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="celular"
                    v-model="establecimiento.celular"
                    placeholder="56978717595"
                    :state="v$.establecimiento.celular.$error === true
                    ? false
                    : null"
                    @keyup='establecimiento.celular = formatSoloNumerosMaxLenght(establecimiento.celular, 11)'
                    @blur.native="v$.establecimiento.celular.$touch"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.celular.$error"
                  id="celularInfo"
                >
                  <p
                    v-for="error of v$.establecimiento.celular.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- Dirección -->
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-25"
            >
              <b-card-sub-title>
                Dirección
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="8"
              class="mb-50"
            >
              <b-card-sub-title >
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>

            <!-- Field: Calle -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Nombre calle *"
                label-for="nombre_calle"
                label-cols-md="4"
              >
                <b-form-input
                  id="nombre_calle"
                  v-model="establecimiento.nombre_calle"
                  placeholder="Ingresa el nombre de la calle"
                  :state="v$.establecimiento.nombre_calle.$error === true
                  ? false
                  : null"
                  @blur.native="v$.establecimiento.nombre_calle.$touch"
                />
                <!-- Mensajes Error Validación -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.nombre_calle.$error"
                  id="correoInfo"
                  class="text-right"
                >
                  <p v-for="error of v$.establecimiento.nombre_calle.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: Numero calle -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Nº calle *"
                label-for="numero"
                label-cols-md="4"
              >
                <b-form-input
                  id="numero"
                  v-model="establecimiento.numero"
                  placeholder="Ingresa el numero"
                  :state="v$.establecimiento.numero.$error === true
                  ? false
                  : null"
                  @blur.native="v$.establecimiento.numero.$touch"
                />
                <!-- Mensajes Error Validación -->
                <b-form-invalid-feedback
                  v-if="v$.establecimiento.numero.$error"
                  id="correoInfo"
                  class="text-right"
                >
                  <p v-for="error of v$.establecimiento.numero.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- departamento: '',
      direccion: '',
      idPeriodoActivo: '',
      estado: null, -->

      <colLinea />

      <!-- Action Buttons -->
      <b-row>
      <b-col
        cols="12"
        md="9"
      >
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="text-right"
      >
        <btnSubmit
          v-if="cargando"
          variant="primary"
          :modulo="nombre_permiso"
          :btnText="btnSubmit"
          @processBtn="submitOption"
        />
      </b-col>
    </b-row>
    </b-form>
  </b-overlay>
</template>

<script>

// Etiquetas //
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BCard, BInputGroup, BInputGroupPrepend,
  BCardSubTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// Imágen //
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

// MIXINS
import { formatos } from '@core/mixins/ui/formatos'

// Validaciones //
import useVuelidate from '@vuelidate/core'
import { required
  , maxLength, email, helpers } from '@vuelidate/validators'

// Componentes //
import btnSubmit from '../../../components/Form/btnSubmit.vue'
import colLinea from '../../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BOverlay,
    BCard,
    BCardSubTitle,

    btnSubmit,
    colLinea,
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.establecimiento.insignia = base64
    })

    return {
      v$: useVuelidate(),
      avatarText,
      previewEl,
      refInputEl,
      inputImageRenderer,
    }
  },
  mixins: [formatos],
  data() {
    return {
      cargando: true,
      nombre_permiso: 'ajustesEstable'
      // required,
      // email,
      // dependenciasOption: [
      //   { value: 'Municipal', text: 'Municipal' },
      //   { value: 'Paricular', text: 'Paricular' },
      //   { value: 'Particular Subvencionado', text: 'Particular Subvencionado' },
      // ],
    }
  },
  props: {
    establecimiento: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar',
    },
  },
  validations() {
    return {
      establecimiento: {
        rbd: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 8 caracteres.'
            , maxLength(8)),
        },
        nombre: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 250 caracteres.'
            , maxLength(250)),
        },
        abreviatura: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 10 caracteres.'
            , maxLength(10)),
        },
        correo: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 11 caracteres.'
            , maxLength(11)),
        },
        nombre_calle: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 150 caracteres.'
            , maxLength(150)),
        },
        numero: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  methods: {
    submitOption() {
      this.v$.establecimiento.$touch()
      if (!this.v$.establecimiento.$invalid) {
        this.$emit('processForm', this.establecimiento)
      }
    },
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.establecimiento.insignia = e.target.result
      }
    },
  },
}
</script>
