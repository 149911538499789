<template>
  <b-form>
    <b-row class="text-center mt-1 pr-2">
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Aproximar promedio de asignatura por semestre o trimestre:"
          label-for="aproximar_asignatura"
          label-cols-md="6"
          label-class="text-right"
        >
          <b-form-checkbox
            checked="1"
            v-model="aproximaciones.aproximar_asignatura"
            class="custom-control-success text-left ml-4 mt-25"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Aproximar periodo:"
          label-for="aproximar_periodo"
          label-cols-md="6"
          label-class="text-right"
        >
          <b-form-checkbox
            checked="1"
            v-model="aproximaciones.aproximar_periodo"
            class="custom-control-success text-left ml-4 mt-25"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Aproximar promedio asignatura final:"
          label-for="aproximar_asignatura_anual"
          label-cols-md="6"
          label-class="text-right"
        >
          <b-form-checkbox
            checked="1"
            v-model="aproximaciones.aproximar_asignatura_anual"
            class="custom-control-success text-left ml-4 mt-25"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>



      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Aproximar promedio final por semestre o trimestre:"
          label-for="aproximar_final"
          label-cols-md="6"
          label-class="text-right"
        >
          <b-form-checkbox
            checked="1"
            v-model="aproximaciones.aproximar_final"
            class="custom-control-success text-left ml-4 mt-25"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- Field: DECIMAS -->
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Decimas *:"
          label-for="decimas"
          label-cols-md="6"
          class="text-right"
        >
          <b-form-input
            id="decimas"
            v-model="aproximaciones.decimas"
            class="ml-2"
            placeholder="Ingresa las decimas"
            :state="v$.aproximaciones.decimas.$error === true
            ? false
            : null"
            @blur.native="v$.aproximaciones.decimas.$touch"
          />
          <!-- Mensajes Error Validación -->
          <b-form-invalid-feedback
            v-if="v$.aproximaciones.decimas.$error"
            id="rbdInfo"
            class="text-right"
          >
            <p v-for="error of v$.aproximaciones.decimas.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

    </b-row>
    <colLinea />

    <!-- Action Buttons -->
    <b-row>
      <b-col
        cols="12"
        md="9"
      >
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="text-right"
      >
        <btnSubmit
          variant="primary"
          :modulo="nombre_permiso"
          :btnText="btnSubmit"
          @processBtn="submitOption"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup,
  BInputGroupPrepend, BFormCheckbox, BFormInvalidFeedback, BFormSelect
} from 'bootstrap-vue'

import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'

import store from '@/store/index'

import { mapActions, mapGetters } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


// Componentes //
import btnSubmit from '../../../components/Form/btnSubmit.vue'
import colLinea from '../../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormSelect,
    // Componentes //
    btnSubmit,
    colLinea,
  },
  data() {
    return {
      nombre_permiso: 'ajustesEstable',
      btnSubmit: 'Guardar',
      cargando: false,
      aproximaciones: {
        tipo_evaluaciones: null,
      },
    }
  },
  validations() {
    return {
      aproximaciones: {
        tipo_evaluaciones: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        decimas: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 2 caracteres.'
            , maxLength(2)),
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      getAproximaciones: 'establecimientos/getAproximaciones',
      getCalendarios: 'calendarios/getCalendarios',
      user: 'auth/user',
    }),
  },
  mounted() {
    this.cargaAproximaciones()
  },
  methods: {
    ...mapActions({
      attempt: 'user/attempt',
      fetchAproximaciones: 'establecimientos/fetchAproximaciones',
      addAproximaciones: 'establecimientos/addAproximaciones',
      updateAproximaciones: 'establecimientos/updateAproximaciones',
      fetchCalendarios: 'calendarios/fetchCalendarios',
    }),
    cargaAproximaciones() {
      this.fetchAproximaciones(this.user.id_establecimiento).then(() => {
        this.aproximaciones = {}
        if (this.getAproximaciones) {
          this.aproximaciones = {
            aproximar_asignatura: this.getAproximaciones.aproximar_asignatura === 1,
            aproximar_periodo: this.getAproximaciones.aproximar_periodo === 1,
            aproximar_final: this.getAproximaciones.aproximar_final === 1,
            aproximar_asignatura_anual: this.getAproximaciones.aproximar_asignatura_anual === 1,
            decimas: this.getAproximaciones.decimas,
            jec: this.getAproximaciones.jec,
            id_decreto: this.getAproximaciones.id_decreto,
            tipo_evaluaciones: this.getAproximaciones.tipo_evaluaciones,
            id_calendario_escolar: this.getAproximaciones.id_calendario_escolar,
          }
        } else {
          this.aproximaciones = {
            aproximar_asignatura: false,
            aproximar_periodo: false,
            aproximar_final: false,
            aproximar_asignatura_anual: false,
            decimas: 2,
            jec: false,
            tipo_evaluaciones: null, // v-select
            id_decreto: 1, // seleccionar el activo
            id_calendario_escolar: 1, // seleccionar el activo
          }
        }
      })
    },
    submitOption() {
      this.v$.aproximaciones.$touch()
      if (!this.v$.aproximaciones.$invalid) {
        this.cargando = true
        if (!this.getAproximaciones) {
          // CREA
          this.addAproximaciones(this.aproximaciones).then(() => {
            this.mensaje()
          })
        } else {
          // EDITA
          const data = {
            aproximar_asignatura: this.aproximaciones.aproximar_asignatura,
            aproximar_asignatura_anual: this.aproximaciones.aproximar_asignatura_anual,
            aproximar_final: this.aproximaciones.aproximar_final,
            aproximar_periodo: this.aproximaciones.aproximar_periodo,
            aproximar_asignatura: this.aproximaciones.aproximar_asignatura,
            decimas: this.aproximaciones.decimas,
            tipo_evaluaciones: this.aproximaciones.tipo_evaluaciones,
            id_decreto: this.aproximaciones.id_decreto,
            id_calendario_escolar: this.aproximaciones.id_calendario_escolar,
            jec: this.aproximaciones.jec,
          }
           this.updateAproximaciones(data).then(() => {
            this.mensaje()
          })
        }
      }
    },
    mensaje() {
      const statusAproximaciones = store.state.establecimientos.status
      if (statusAproximaciones === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Aproximaciones editadas 👍',
            icon: 'CheckIcon',
            text: `Las aproximaciones del establecimiento, fueron actualizadas con éxito!`,
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
          timeout: 4000,
        })
        this.cargando = false
      }
      else {
        this.$swal({
          title: 'Error!',
          text: store.state.establecimientos.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.cargando = false
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style>

</style>
