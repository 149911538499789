<template>
  <!-- <component> -->
    <b-card
      aria-hidden="true"
      class="mb-0"
    >
      <b-tabs
        pills
      >

        <!-- Tab: Perfil -->
        <b-tab lazy active>
          <template #title>
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Establecimiento</span>
          </template>
          <establecimiento-tab-perfil
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Calendario -->
        <!-- <b-tab>
          <template #title>
            <feather-icon
              icon="CalendarIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Calendario</span>
          </template>
          <establecimiento-tab-calendarios class="mt-2 pt-75" />
        </b-tab> -->

        <!-- Tab: Aproximaciones -->
        <b-tab lazy>
          <template #title>
            <feather-icon
              icon="ToggleLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Promedios</span>
          </template>
          <establecimiento-tab-aproximaciones class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Informes -->
        <!-- <b-tab>
          <template #title>
            <feather-icon
              icon="BarChartIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Informes</span>
          </template>
          <establecimiento-tab-informes class="mt-2 pt-75" />
        </b-tab> -->

        <!-- Tab: Decreto -->
        <!-- <b-tab lazy>
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Decreto</span>
          </template>
          <establecimiento-tab-decreto class="mt-2 pt-75" />
        </b-tab> -->

      </b-tabs>
    </b-card>
  <!-- </component> -->
</template>

<script>
// ETIQUETAS
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'

import { mapGetters, mapActions, mapMutations } from 'vuex'

// COMPONENTES HIJOS
import EstablecimientoTabPerfil from './components/EstablecimientoTabPerfil.vue'
import EstablecimientoTabAproximaciones from './components/EstablecimientoTabAproximaciones.vue'
import EstablecimientoTabCalendarios from './components/EstablecimientoTabCalendarios.vue'
import EstablecimientoTabInformes from './components/EstablecimientoTabInformes.vue'
import EstablecimientoTabDecreto from './components/EstablecimientoTabDecreto.vue'

export default {
  components: {
    // ETIQUETAS
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    // COMPONENTES HIJOS
    EstablecimientoTabPerfil,
    EstablecimientoTabAproximaciones,
    EstablecimientoTabCalendarios,
    EstablecimientoTabInformes,
    EstablecimientoTabDecreto,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      // getSelectedEstablecimiento: 'establecimientos/getSelectedEstablecimiento'
    }),
  },
  mounted() {
    this.cargarUser()
  },
  methods: {
    ...mapActions({
      fetchEstablecimiento: 'establecimientos/fetchEstablecimiento',
      updateEstablecimiento: 'establecimientos/updateEstablecimiento',
      attempt: 'auth/attempt',
    }),
    cargarUser() {
      if (!this.user) {
        this.attempt().then(() => {
          this.cargarEstablecimiento(this.user.id_establecimiento)
        })
      } else {
        this.cargarEstablecimiento(this.user.id_establecimiento)
      }
    },
    cargarEstablecimiento(id_establecimiento) {
      this.fetchEstablecimiento(id_establecimiento).then(() => {})
    },
  },
}
</script>

<style>

</style>
